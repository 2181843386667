<template>
  <div class="announcements template-1">
    <page-header>
      <h1>{{ entity }} {{ translations.tcAnnouncements }}</h1>
    </page-header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load($event)" :tooltip="translations.tcCurrentHome"
        :i18n="translations.components"></camp-selector>
      <!-- / Camp Selector -->
      <page-body :bg="true">
        <section class="body-header">
          <div class="action-bar d-flex justify-content-between">
            <div class="f-container d-lg-flex d-md-block align-items-center">
              <p>{{ translations.tcView }}:</p>
              <div class="filters btn-group d-flex">
                <a href="#" @click.prevent="filterAnnCards($event)" class="btn btn-outline"
                  :class="{ 'btn-primary': filter === 'All' }" title="All">{{ translations.tcAll }}</a>
                <a href="#" @click.prevent="filterAnnCards($event)" class="btn btn-outline"
                  :class="{ 'btn-primary': filter === 'Camp' }" title="Camp">{{ translations.tcCamp }}</a>
                <a href="#" @click.prevent="filterAnnCards($event)" class="btn btn-outline"
                  :class="{ 'btn-primary': filter === 'State' }" title="State">{{ stateOrCountry }}</a>
                <a href="#" @click.prevent="filterAnnCards($event)" class="btn btn-outline"
                  :class="{ 'btn-primary': filter === 'International' }" title="International">{{
                  translations.tcInternational }}</a>
                <b-dropdown v-if="moreOptions.length > 0">
                  <template #button-content> {{ translations.tcMore }}</template>
                  <b-dropdown-item @click.prevent="filterAnnCards($event)" href="#" title="All">
                    {{ translations.tcAllCommittees }}
                  </b-dropdown-item>
                  <b-dropdown-item v-for="(option, ox) in moreOptions" :key="ox" @click.prevent="filterAnnCards($event)"
                    href="#" :title="option.text">{{ option.displayText }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="actions d-flex">
              <b-button v-if="iCanSeeAny(secured_announcement_add_controls)" variant="tertiary"
                class="flex-0 mr-0 mr-sm-3 d-block w-100-sd mb-3 mb-sm-0" @click="addAnnouncement">{{
                translations.tcAddAnnouncement }}</b-button>
              <b-button v-if="iCanSeeAny(secured_announcement_manage_controls)" variant="tertiary"
                class="flex-0 mr-0 mr-sm-3 d-block w-100-sd mb-3 mb-sm-0" @click="manageAnnouncements">{{
                translations.tcManage }}</b-button>
              <Search @handleSearchClick="handleSearchClick($event)"
                @searchTermChanged="handleSearchTermChange($event)" />
            </div>
          </div>
        </section>
        <section class="ann-cards">
          <div class="row">
            <div class="nonefound" v-if="annCards.length === 0">{{ translations.tcNoAnnoucementsWereFound }}.</div>
            <div v-for="(ann, index) in filteredAnnCards" :key="index" class="col-4 mb-4 col-100-sd">
              <AnnCard :obj="ann" @view_more="handleViewMoreClick($event)" :i18n="translations.components"></AnnCard>
            </div>
          </div>
        </section>
        <b-button v-if="annCards.length < 7 && annCards.length > 0" :disabled="moreDisabled" @click="handleMoreClick"
          variant="tertiary" class="btn-w-sm flex-0 mt-3 mr-0 mr-sm-3 d-block w-100-sd mb-0">{{
          translations.tcMoreAnnoucements }}</b-button>
      </page-body>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AnnCard from '@/components/ann-card/AnnCard.vue'
import CampSelect from '@/components/CampSelect.vue'
import iDownArrow from '@/assets/svgs/icon-down-arrow.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import Search from '@/components/SearchInput.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'announcements',
  mixins: [translationMixin],
  data() {
    return {
      filter: 'All',
      search: '',
      annCards: [],
      moreDisabled: false,
      moreOptions: [],
      returnedRows: 6,
      secured_announcement_add_controls: {
        camp_announcement_add_button: '05de1755-ddaa-4a45-a459-e55122cfe128',
        state_announcement_add_button: '183af598-be68-4ef2-93b5-3949f027db83',
        country_announcement_add_button: '789c7b00-95c2-4eda-a4aa-d661e4004cc6',
        committee_announcement_add_button: 'fe4b0c01-e2f8-4d5d-825f-52472bbbeddd',
      },
      secured_announcement_manage_controls: {
        camp_announcement_manage_button: 'ec30ef5e-3984-49f8-a8e2-6a1e9c27da5a',
        state_announcement_manage_button: '1da583a9-fbf4-4545-a169-8edc37b36778',
        country_announcement_manage_button: '02ae7b95-6346-43ae-9b53-7f66ad9dc066',
        committee_announcement_manage_button: 'ca0d2880-6dd6-4f82-a0f1-25f479209abd',
      },
      stateOrCountry: 'State',
      translations: {},
    }
  },
  components: {
    AnnCard: AnnCard,
    campSelector: CampSelect,
    iDownArrow: iDownArrow,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    Search: Search,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      clearAnnouncementForm: 'announcements/clearAnnouncementForm',
      getAddableCommittees: 'announcements/getAddableCommittees',
      getAnnouncementMessages: 'announcements/getAnnouncementMessages',
      getAnnouncementsForCamp: 'announcements/getAnnouncementsForCamp',
      getRegionalCommittees: 'announcements/getRegionalCommittees',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedAnnouncementKey: 'user/setSelectedAnnouncementKey',
    }),
    async page_load(evt) {
      if (!evt) {
        // execute only if evt is undefined
        try {
          this.setLoadingStatus(true)
          await Promise.all([
            this.clearAnnouncementForm(),
            this.getAddableCommittees(),
            this.getAnnouncementMessages({ quick: false, searchterm: this.search }),
            this.getRegionalCommittees(),
          ]).then(() => {
            this.annCards = this.announcements.slice(0, this.returnedRows)
            this.constructMoreOptions()
          })
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    addAnnouncement() {
      this.$router.push({
        path: '/get-connected/stay-informed/announcements/add/add-announcement',
      })
    },
    constructMoreOptions: function () {
      // If unwanted committees appear, see sproc committees_by_individual_select_for_announcements_tc3
      this.moreOptions = !!this.addableCommittees.areas
        ? this.addableCommittees.areas.filter((aca) => {
            const option = this.convertValForTranslation(aca.text)
            aca.displayText = this.translations.commonText['committee-types'].hasOwnProperty(option)
              ? this.translations.commonText['committee-types'][option]
              : aca.text
            return aca.text !== 'All Committees'
          })
        : []
      if (!!this.userCommittees && this.userCommittees.areas.length > 0) {
        this.userCommittees.areas.forEach((uc) => {
          this.regionalCommittees.forEach((rc) => {
            if (rc.cmt_key === uc.value) {
              this.moreOptions.push({
                displayText: rc.value,
                text: rc.value,
                value: 'regional-committee ' + rc.cmt_org_key,
              })
            }
          })
        })
      }
      this.moreOptions.sort((a, b) => {
        let na = a.text.toUpperCase()
        let nb = b.text.toUpperCase()
        return na < nb ? -1 : na > nb ? 1 : 0
      })
    },
    manageAnnouncements() {
      this.$router.push({
        path: '/admin/announcements/manage-announcements',
      })
    },
    createTitle(code) {
      return {
        cn: 'Country',
        st: 'State',
        menu: '',
        xx: '',
      }[code || 'xx']
    },
    filterAnnCards(evt) {
      this.filter = !!evt.target.title ? evt.target.title : 'All'
    },
    goToAdd() {
      this.$router.push(`announcements/add/add-${this.filter.toLowerCase()}-announcement`)
    },
    handleMoreClick() {
      this.annCards = this.announcements
    },
    async handleViewMoreClick(evt) {
      await this.setSelectedAnnouncementKey(evt.msg_key)
      this.$router.push({
        name: `announcement-detail`,
      })
    },
    handleSearchTermChange(evt) {
      this.moreDisabled = evt.term.length > 0 && evt.label === 'SEARCH'
      this.returnedRows = this.moreDisabled ? 999 : 6
    },
    async handleSearchClick(searchTerm) {
      this.search = searchTerm
      this.moreDisabled = false
      this.annCards = this.announcements
        .filter(
          (data) =>
            JSON.stringify(data)
              .replace(/("\w+":)/g, '')
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) !== -1
        )
        .slice(0, this.returnedRows)
    },
  },
  computed: {
    ...mapGetters({
      addableCommittees: 'announcements/addableCommittees',
      announcements: 'announcements/announcements',
      announcements_for_camp: 'announcements/announcementsForCamp',
      demograph: 'user/demograph',
      iCanSeeAny: 'user/iCanSeeAny',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      regionalCommittees: 'announcements/regionalCommittees',
      user_camp_key: 'user/userCampKey',
      userCommitteeCount: 'user/userCommitteeCount',
      userCommittees: 'user/userCommittees',
      userLogin: 'user/userLogin',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path) //.substr(0, 42))
    },
    officer_toolbar_in_use() {
      return this.user_camp_key !== this.demograph.camp_key
    },
    entity() {
      return this.createTitle(this.$route.path.split('/')[4])
    },
    filteredAnnCards() {
      return !!this.filter && this.filter.toLowerCase() !== 'all'
        ? this.announcements.filter((ac) => {
            return (
              ac.cat.toLowerCase() === (this.filter.toLowerCase() || '') ||
              (!!ac.filter && ac.filter.findIndex((fi) => fi === this.filter.toLowerCase()) > -1) ||
              (!!ac.committee && ac.committee.toLowerCase() === (this.filter.toLowerCase() || '')) ||
              (ac.cat === 'state' && this.filter.toLowerCase() === 'country')
            )
          })
        : this.annCards
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('ann-card', 'security-banner', 'camp-select'),
        await this.getComponentTranslations('common.committee-types'),
      ]).then((results) => {
        this.stripReadableText(results[2], 'text')
        let componentResults = results[1]
        componentResults['ann-card'] = {
          // combining `ann-card` and `committee-types` translations
          ...componentResults['ann-card'],
          ...this.translations.commonText['committee-types'],
        }
        const translatedText = {
          ...componentResults,
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
      })
      this.stateOrCountry =
        this.userLogin.membertype.toLowerCase() === "usa" ? this.translations.tcState : this.translations.tcCountry
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  async mounted() {
    this.filterAnnCards({ target: { title: this.createTitle(this.$route.path.split('/')[4]) } })
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.nonefound {
  margin: 30px 50px;
}

.announcements {
  .page-body.container {
    max-width: 1160px;
  }

  .page-body {
    margin-top: 34px;

    .body-header {
      .actions {
        @include breakpoint(sm) {
          flex-wrap: wrap;
        }

        .search {
          @include breakpoint(sm) {
            order: 1;
          }
        }

        .btn {
          padding-left: 22px;
          padding-right: 22px;

          @include breakpoint(sm) {
            order: 2;
          }
        }
      }
    }

    .action-bar {
      @include breakpoint(sm) {
        flex-wrap: wrap;
      }
    }

    .f-container {
      @include breakpoint(sm) {
        order: 2;
        align-items: flex-start !important;
      }

      p {
        margin-bottom: 0;
        margin-right: 13px;
        color: $gray-200;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;

        @include breakpoint(sm) {
          margin-right: 3rem;
        }
      }
    }

    .filters {
      @include breakpoint(sm) {
        flex-wrap: wrap;
        order: 1;
      }

      .btn {
        padding: 11px 13px;
        font-weight: 700;

        @include breakpoint(sm) {
          justify-content: center;

          &:nth-of-type(1) {
            width: 120%;
            flex: 0 0 33%;
          }

          &:nth-of-type(2) {
            width: calc(33% + 1px);
            flex: 0 0 calc(33% + 1px);
          }

          &:nth-of-type(3) {
            width: calc(34% + 1px);
            flex: 0 0 calc(34% + 1px);
          }

          &:nth-of-type(4) {
            margin-left: 0;
            margin-top: -1px;
            width: 66%;
          }

          &:nth-of-type(5) {
            width: calc(34% + 1px);
            flex: 0 0 calc(34% + 1px);
            margin-top: -1px;
          }
        }
      }

      .btn-primary {
        color: #fff !important;
      }

      .btn-outline {
        color: $gray-200;
        display: flex;
        align-items: center;

        svg {
          margin-left: 5px;
        }
      }
    }

    .ann-cards {
      .ann-card {
        height: 100%;
      }
    }
  }
}
</style>
